import { useState } from 'react'

import { useRouter } from 'next/router'

import { useGetTranslations } from '@/i18n/index.tsx'

import { useApplicationContext } from '@/context/ApplicationContext.tsx'
import { useAuthContext } from '@/context/AuthContext.tsx'

import { NOTIFICATION_TYPE } from '@/constants/constants.ts'

import { redirectToDashboard } from '@/utils/functionUtils'

import { LoginFormComponent } from '@/components/shared/LoginFormComponent/LoginFormComponent.tsx'
import ToastNotificationComponent from '@/components/shared/ToastNotificationComponent/ToastNotificationComponent'

type FormDataT = {
    email: string
    token?: string
}

export default function LoginPage() {
    const { supabaseClient, setSession, setProfile } = useAuthContext()
    const { setToastNotification } = useApplicationContext()
    const { TOAST_NOTIFICATIONS_TEXT } = useGetTranslations()
    const router = useRouter()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [loginSteps, setLoginSteps] = useState(0)

    const handleVerifyOTP = async (formData: FormDataT) => {
        setLoading(true)

        const { token } = formData

        // Make sure both email and token are not null or undefined
        if (!email || !token) {
            console.error('Email and token are required for verification.')
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: 'Email and token are required.',
            })
            setLoading(false)
            return
        }

        const { error, data } = await supabaseClient.auth.verifyOtp({
            email,
            token,
            type: 'email',
        })

        // Immediately check if there was an error or if data.session is null
        if (error || !data.session) {
            console.error(
                'Error verifying OTP or session is null:',
                error?.message || 'Session is null'
            )
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: error?.message || 'Error verifying OTP',
            })
            setLoading(false)
            return
        }

        // Assuming you want to fetch user profile after successful OTP verification
        const { data: profileData, error: profileError } = await supabaseClient
            .from('profiles')
            .select('*')
            .eq('id', data.user.id) // Make sure to access user ID correctly based on the response structure
            .single()

        if (profileError) {
            console.error('Error fetching user profile:', profileError.message)
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            })
            setLoading(false)
            return
        }

        // Continue with setting session and redirecting user...
        setSession(data.session)
        setProfile(profileData)
        redirectToDashboard(profileData.roles[0], router)
        setLoading(false)
    }

    const handleLogin = async (formData: FormDataT) => {
        setLoading(true)

        const { email } = formData

        if (!email) {
            console.error('Email is required for logging in.')
            setLoading(false)
            // Optionally, show a toast notification for missing email
            setToastNotification({ type: NOTIFICATION_TYPE.ERROR, message: 'Email is required.' })
            return
        }

        const { error } = await supabaseClient.auth.signInWithOtp({
            email,
            options: {
                shouldCreateUser: false,
            },
        })

        if (error) {
            console.error('Email is required for logging in.')
            setLoading(false)
            setToastNotification({ type: NOTIFICATION_TYPE.ERROR, message: error.message })
            return
        }
        setEmail(email)
        setLoginSteps(1)
        setLoading(false)
    }

    return (
        <div className="justify-between mt-10 text-center animate-fade animate-duration-1000">
            <ToastNotificationComponent />

            <LoginFormComponent
                handleLogin={handleLogin}
                handleVerifyOTP={handleVerifyOTP}
                loginSteps={loginSteps}
                loading={loading}
            />
        </div>
    )
}
