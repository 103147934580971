import { useForm } from 'react-hook-form'

import BasicButton from '../BasicButton/BasicButton.tsx'
import InputComponent from '../InputComponent/InputComponent.tsx'
import { LockClosedIcon, UserIcon } from '@heroicons/react/24/outline'

import { useGetTranslations } from '@/i18n/index.tsx'

export const LoginFormComponent = ({ handleLogin, handleVerifyOTP, loginSteps, loading }) => {
    const { register, handleSubmit } = useForm()
    const { LOGIN_FORM_COMPONENT_TEXT, ACTION_BUTTONS_LABELS } = useGetTranslations()

    // Conditionally render forms based on loginSteps
    const renderForm = () => {
        if (loginSteps === 0) {
            // Step 1: Email input for OTP request
            return (
                <form
                    onSubmit={handleSubmit(handleLogin)}
                    className="max-w-xs p-5 m-auto mt-5 rounded-md shadow-md bg-base-100 dark:bg-opacity-5 dark:bg-slate-50"
                >
                    <div className="w-full form-control">
                        <InputComponent
                            className="w-full input input-bordered"
                            type="email"
                            placeholder="Email"
                            {...register('email', { required: true })}
                            label={LOGIN_FORM_COMPONENT_TEXT.EMAIL}
                        />
                    </div>

                    <BasicButton
                        isLoading={loading}
                        disabled={loading}
                        type="submit"
                        className="w-32 mt-5 btn"
                    >
                        {ACTION_BUTTONS_LABELS.SUBMIT}
                    </BasicButton>
                </form>
            )
        } else if (loginSteps === 1) {
            // Step 2: OTP input for verification
            return (
                <form
                    onSubmit={handleSubmit(handleVerifyOTP)}
                    className="max-w-xs p-5 m-auto mt-5 rounded-md shadow-md bg-base-100 dark:bg-opacity-5 dark:bg-slate-50 animate-fade"
                >
                    <div className="w-full form-control">
                        <InputComponent
                            className="w-full input input-bordered"
                            label={LOGIN_FORM_COMPONENT_TEXT.OTP_LABEL}
                            type="number"
                            placeholder={LOGIN_FORM_COMPONENT_TEXT.OTP}
                            {...register('token', { required: true })}
                        />
                    </div>

                    <BasicButton
                        isLoading={loading}
                        disabled={loading}
                        type="submit"
                        className="w-32 mt-5 btn"
                    >
                        {LOGIN_FORM_COMPONENT_TEXT.VERIFY_OTP}
                    </BasicButton>
                </form>
            )
        }
    }

    return (
        <>
            {loginSteps === 0 ? (
                <h1 className="flex justify-center gap-1 text-2xl font-semibold animate-fade">
                    <UserIcon className="w-12 h-12" />
                    <span className="my-auto">{LOGIN_FORM_COMPONENT_TEXT.LOGIN}</span>
                </h1>
            ) : (
                <h1 className="flex justify-center gap-1 text-2xl font-semibold animate-fade">
                    <LockClosedIcon className="w-12 h-12" />
                    <span className="my-auto">{LOGIN_FORM_COMPONENT_TEXT.VERIFY_OTP}</span>
                </h1>
            )}

            {renderForm()}
        </>
    )
}
